<script setup>
import { defineEmits, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import api from '../store/API'
import ApiModal from '../general/components/ApiModal.vue'
import PollDetail from './PollDetail.vue'
import PollForm from './PollForm.vue'
import DarioButton from '../general/components/DarioButton.vue'

const emit = defineEmits(['close'])

const store = useStore()

const props = defineProps({
  pollId: Number,
})

onMounted(async () => {
  await store.dispatch('Polls/fetchPoll', props.pollId)
})

const poll = computed(() => store.getters['Polls/pollById'](props.pollId))
const editMode = ref(false)
const editablePoll = ref(null)

const edit = () => {
  editMode.value = true
  editablePoll.value = JSON.parse(JSON.stringify(poll.value))
}

const save = async () => {
  await api.putForm(`/production/poll_questions/${editablePoll.value.id}`, cleanupPoll())
  editMode.value = false
}

const cleanupPoll = () => {
  // If image is a string it means it's not changed, so we don't want to send it
  return {
    ...editablePoll.value,
    answers: editablePoll.value.answers.map((answer) => {
      return {
        ...answer,
        image: typeof answer.image === 'string' ? null : answer.image,
      }
    }),
    image: typeof editablePoll.value.image === 'string' ? null : editablePoll.value.image,
  }
}
</script>

<template>
  <ApiModal v-if="poll" size="wide" @close="emit('close')">
    <template #header>{{ $t('production.poll.live') }}</template>
    <div class="modal-body">
      <div v-if="editMode">
        <PollForm v-model="editablePoll" />
        <div class="actions">
          <DarioButton class="float-right" type="success" :title="$t('production.poll.save')" @click="save" />
          <DarioButton class="float-right" :title="$t('production.poll.cancel')" @click="editMode = false" />
        </div>
      </div>
      <PollDetail v-else :id="poll.id" :poll="poll">
        <template #actions>
          <DarioButton class="float-right" icon="edit" @click="edit" />
        </template>
      </PollDetail>
    </div>
  </ApiModal>
</template>

<style lang="scss" scoped>
.actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: flex-end;
}
</style>
