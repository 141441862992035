<script setup>
import PollModal from './PollModal.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const open = ref(false)

const store = useStore()
const hasRunningPoll = computed(() => {
  return store.getters['Polls/hasRunningPoll']
})
const runningPoll = computed(() => store.state.Polls.runningPoll)

const openModal = () => {
  open.value = true
}

const closeModal = () => {
  open.value = false
}
</script>

<template>
  <div class="running-poll-container">
    <button v-if="hasRunningPoll" class="running-poll-button" @click="openModal">
      <i class="fa fa-circle" />
      Actieve Poll
    </button>
  </div>
  <PollModal v-if="open" :pollId="runningPoll.id" @close="closeModal" />
</template>

<style lang="scss" scoped>
.running-poll-container {
  height: 46px;
  padding: 0 15px;
  margin-left: 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  .fa-circle {
    color: #5ebd5e;
    margin-right: 5px;

    &::before {
      animation: pulse 3s infinite ease-in-out;
    }
  }
}

.running-poll-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 30px;
  border: none;
  background: #5ebd5e53;
  background-size: 18px;
  cursor: pointer;
  outline: none;
}
@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  20% {
    opacity: 0.2;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
</style>
