<template>
  <div class="row">
    <div class="col-md-1 image-uploader">
      <DarioImageUploader :modelValue="image" :squared="true" @update:modelValue="$emit('update:image', $event)" />
    </div>

    <div class="col-md-8">
      <input
        :value="body"
        class="string required form-control"
        name="name"
        @change="$emit('update:body', $event.target.value)"
      />
    </div>

    <div class="col-md-2">
      <label>
        <input
          type="checkbox"
          name="fixed_winner"
          :checked="fixedWinner"
          @change="$emit('update:fixedWinner', $event.target.value)"
        />
        Vaste winnaar
      </label>
    </div>

    <div class="col-md-1">
      <button type="button" class="btn btn-danger" @click.prevent="$emit('remove')">Verwijder</button>
    </div>
  </div>
</template>

<script>
import DarioImageUploader from '../general/components/DarioImageUploader.vue'

export default {
  name: 'PollAnswer',
  components: { DarioImageUploader },
  props: ['body', 'image', 'fixedWinner'],
  emits: ['update:body', 'update:image', 'update:fixedWinner', 'remove'],
}
</script>

<style scoped>
.image-uploader {
  height: 30px;
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  align-items: center;
}
</style>
