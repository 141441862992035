<script setup>
import { computed, onMounted, ref } from 'vue'
import api from '../store/API'
import { useStore } from 'vuex'
import DarioProgressBar from '../general/components/DarioProgressBar.vue'
import PollVotesModal from './PollVotesModal.vue'
import DarioButton from '../general/components/DarioButton.vue'

const props = defineProps({
  id: Number,
  poll: Object,
})

const store = useStore()
const poll = computed(() => store.getters['Polls/pollById'](props.id))

const isRunning = computed(() => poll.value.state === 'voting')
const isHidden = computed(() => poll.value.state === 'hidden')

const answerType = (index) => {
  return ['info', 'warning', 'danger'][index % 3]
}

onMounted(async () => {
  // If the poll is created server-side, it will be passed the poll as a prop, otherwise we have to do a request
  if (props.poll) {
    store.commit('Polls/setPoll', props.poll)
  } else {
    await store.dispatch('Polls/fetchPoll', props.id)
  }
  await store.dispatch('Polls/subscribeToPoll', props.id)
})

const start = async () => {
  await api.post(`/production/poll_questions/${props.id}/start`)
}

const stop = async () => {
  await api.post(`/production/poll_questions/${props.id}/stop`)
}

const hide = async () => {
  await api.post(`/production/poll_questions/${props.id}/hide`)
}

const openedAnswerId = ref(null)

const openAnswerVotes = (id) => {
  openedAnswerId.value = id
}

const closeAnswerVotes = () => {
  openedAnswerId.value = null
}
</script>

<template>
  <div v-if="poll">
    <div class="header">
      <div>
        <slot name="title" />
      </div>
      <div class="actions">
        <DarioButton
          v-if="!isHidden"
          class="float-right"
          type="info"
          icon="power-off"
          :title="$t('production.poll.hide')"
          @click="hide"
        />
        <DarioButton
          v-if="isRunning"
          class="float-right"
          type="danger"
          icon="power-off"
          :title="$t('production.poll.stop')"
          @click="stop"
        />
        <DarioButton
          v-else
          class="float-right"
          type="success"
          icon="power-off"
          :title="$t('production.poll.start')"
          @click="start"
        />
        <slot name="actions" />
      </div>
    </div>
    <div>
      <slot name="description" />
      <h4>Poll: {{ poll.title }}</h4>
      <p>{{ poll.content }}</p>

      <div v-for="(answer, index) in poll.answers" :key="answer.id">
        <DarioProgressBar :label="answer.body" :percentage="answer.in_percents" :type="answerType(index)">
          <template #score>
            <span v-if="answer.fixed_winner" class="label label-info winner-label">
              {{ $t('production.poll.fixed_winner') }}
            </span>
            <a @click="openAnswerVotes(answer.id)"> {{ answer.in_percents }} ({{ answer.votes_count }}) </a>
          </template>
        </DarioProgressBar>
      </div>
    </div>
  </div>
  <PollVotesModal v-if="openedAnswerId" :answerId="openedAnswerId" @close="closeAnswerVotes" />
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  gap: 5px;
}

.winner-label {
  margin-right: 10px;
  padding: 2px 5px;
}
</style>
