const removeAllImages = (event) => {
  event.preventDefault()

  const source = event.target.closest('a')
  const selector = source.dataset.target
  const fields = document.querySelector(selector).querySelectorAll('.nested-fields')

  fields.forEach((field) => {
    const destroyField = field.querySelector('input[name*="_destroy"]')
    if (destroyField) {
      destroyField.value = 1
    }
    field.style.display = 'none'
  })
}

const programTypeChange = (event) => {
  document.querySelectorAll('[data-only-program-type]').forEach((element) => {
    element.style.display = 'none'
  })

  if (event) {
    const type = event.target.value

    document.querySelectorAll(`[data-only-program-type="${type}"]`).forEach((element) => {
      element.style.display = 'block'
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-role="remove-all-images"]').forEach((element) => {
    element.addEventListener('click', removeAllImages)
  })

  document.getElementById('program_program_type')?.addEventListener('change', programTypeChange)
  programTypeChange()

  document.querySelectorAll('[data-role=production-role-select]').forEach((el) => {
    $(el)
      .select2({ placeholder: el.dataset.placeholder })
      .on('change', (e) => {
        const detail = {}
        if (Object.hasOwn(e, 'added')) {
          detail.dj_id = e.added.id
          detail.operation = 'added'
        } else {
          detail.dj_id = e.removed.id
          detail.operation = 'removed'
        }

        const customEvent = new CustomEvent('dj:change', {
          bubbles: true,
          detail: detail,
        })
        el.dispatchEvent(customEvent)
      })
  })

  document.querySelectorAll('[data-dj-images-url]').forEach((element) => {
    const url = element.dataset.djImagesUrl
    const model = document.body.classList.contains('broadcasts') ? 'broadcast' : 'program'

    element.addEventListener('dj:change', (event) => {
      const { operation, dj_id } = event.detail
      if (operation == 'added') {
        fetch(`${url}/${dj_id}?model=${model}`)
          .then((response) => response.text())
          .then((html) => {
            const djEl = document.querySelector('.dj_images')
            const tempDiv = document.createElement('div')
            tempDiv.innerHTML = html
            djEl.parentNode.insertBefore(tempDiv, djEl)
          })
      } else {
        document.querySelectorAll(`[data-dj-id='${dj_id}']`).forEach((e) => e.remove())
      }
    })
  })
})
