<template>
  <div>
    <div v-if="name" class="image-selector">
      <div class="image-selector__current">
        <h6>Current Image</h6>
        <img v-if="currentImage" :src="currentImage" alt="Current Image" />
        <em v-else>This artist does not have an image yet. Choose one on the right.</em>
      </div>
      <div class="image-selector__images">
        <h6>Select different image</h6>
        <div v-if="loadingImages" class="loading">
          <p><i class="fa fa-spinner fa-spin" /> Loading...</p>
        </div>
        <div v-else class="image-selector__images__options">
          <div
            v-for="image in images"
            :key="image"
            class="image-selector__images__options__image"
            :class="{ selected: newImage === image }"
            @click="toggleNewImage(image)"
          >
            <img :src="image" />
          </div>
        </div>
        <div
          class="image-selector__images__options__image image-selector__images__options__image--custom"
          :class="{ selected: newImage === 'custom' }"
          @click="toggleNewImage('custom')"
        >
          <div>
            <strong>Custom Image</strong>
            <input
              ref="customFileElement"
              type="file"
              name="artist[image]"
              @change="setCustomImage($event.target.files[0])"
            />
            <img v-if="customImage" :src="customImagePreview" />
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <em>Fill out the artist name to choose an image.</em>
    </div>

    <input v-if="newImage && newImage !== 'custom'" type="hidden" name="image_url" :value="newImage" />
  </div>
</template>

<script>
import api from '../store/API'
import { debounce } from '@/general/utils/debounce'

const nameElement = document.querySelector('#artist_name')

export default {
  props: ['currentImage'],
  data() {
    return {
      name: nameElement.value,
      images: [],
      loadingImages: false,
      newImage: null,
      customImage: null,
    }
  },
  computed: {
    customImagePreview() {
      return this.customImage ? URL.createObjectURL(this.customImage) : null
    },
  },
  watch: {
    newImage(newValue, oldValue) {
      if (oldValue === 'custom') {
        this.customImage = null
        this.$refs.customFileElement.value = ''
      }
    },
  },
  mounted() {
    nameElement.addEventListener('input', () => {
      this.name = nameElement.value
      this.loadingImages = true
      this.debounceFetchImages()
    })
    this.fetchImages()
  },
  methods: {
    toggleNewImage(image) {
      if (this.newImage === image) {
        this.newImage = null
      } else {
        this.newImage = image
      }
    },
    async fetchImages() {
      if (!this.name) {
        return
      }

      this.loadingImages = true

      const response = await api.get(`/enrichment/artists/images`, { params: { artist_name: this.name } })

      this.images = response.data
      this.newImage = null
      this.loadingImages = false
    },
    debounceFetchImages: debounce(function () {
      this.fetchImages()
    }, 1000), // High debounce due to rate limiting on the images provider API.
    setCustomImage(file) {
      this.customImage = file
      this.newImage = 'custom'
    },
  },
}
</script>

<style scoped lang="scss">
.image-selector {
  display: flex;
  gap: 1rem;

  h6 {
    margin-top: 0;
    font-weight: bold;
  }

  &__current {
    padding-right: 1rem;
    border-right: 2px solid #ccc;

    max-width: 32rem;

    img {
      max-width: 100%;
    }
  }

  &__images {
    flex: 1;

    &__options {
      margin: 1rem 0;

      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));

      gap: 1rem;
      flex-wrap: wrap;

      &__image {
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: #edece6;
        padding: 1rem;

        transition: background 300ms;

        cursor: pointer;

        &:hover {
          background: #c8e1ea;
        }

        &.selected {
          background: #1aaada;
          color: white;
        }

        img {
          max-width: 100%;
          max-height: 250px;
        }

        &--custom {
          text-align: center;

          input {
            margin: auto;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
</style>
